<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card dark color="#c8744c">
          <v-card-title>Choose an Unit</v-card-title>
          <v-card-text>
            <v-select
                :items="varsities"
                item-value="id"
                item-text="name"
                v-model="varsity_id"
                label="Choose a varsity"
            >
            </v-select>
            <v-select
                :items="units"
                item-value="id"
                item-text="name"
                label="Choose a unit"
                v-model="unit_id"
            >
            </v-select>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5">
        <v-card dark color="#457865">
          <v-card-title>
            Write SMS
          </v-card-title>
          <v-card-subtitle>
            <v-checkbox v-model="next_available" label="Available for new subscribers"></v-checkbox>
          </v-card-subtitle>
          <v-card-text>
            <v-textarea v-model="message" label="Write message here" counter>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn
                @click="sendMessage"
                large
                block
                rounded
                color="green"
                :disabled="loading || !isValidForm"
                :loading="loading"
            >
              Send
              <span v-if="unit">({{ unit.sms_alerts_count }} students)</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card color="#467655" max-height="600px" class="overflow-y-auto">
          <v-card-title class="white--text">Recent SMS</v-card-title>
          <div v-if="unit">
            <v-card v-for="history in unit.sms_alert_histories" :key="history.id" dark elevation="4"
                    class="blue-grey ma-1">
              <v-card-text v-text="history.message"></v-card-text>
              <v-card-subtitle class="text-right">{{ history.reach }} reach</v-card-subtitle>
            </v-card>
            <v-divider></v-divider>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios"
import Swal from "sweetalert2"

export default {
  data() {
    return {
      loading: false,
      varsities: [],
      units: [],
      unit: '',
      varsity_id: '',
      unit_id: '',
      message: '',
      next_available: false,
    }
  },
  computed: {
    form() {
      let formData = new FormData()
      formData.append('unit_id', this.unit_id)
      formData.append('message', this.message)
      formData.append('next_available', this.next_available ? "1" : "0")
      return formData
    },
    isValidForm() {
      return !!this.unit_id && !!this.message
    }
  },
  methods: {
    initialize() {
      const url = 'admin/varsity?filter=sms'
      axios.get(url).then((response) => {
        this.varsities = response.data
      })
    },
    searchSelectedVarsity() {
      let result = this.varsities.filter(varsity => {
        return varsity.id === this.varsity_id
      })
      return result[0].units
    },
    searchSelectedUnit() {
      let result = this.units.filter(unit => {
        return unit.id === this.unit_id
      })
      return result[0]
    },
    sendMessage() {
      this.loading = true
      const url = 'admin/send-sms-alert'
      axios.post(url, this.form).then(() => {
        Swal.fire('Success!', 'SMS sent successfully')
        this.clear()
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    clear() {
      this.message = ''
    },
    totalSubscriber() {
      this.varsities.forEach((varsity) => {
        let total = 0
        varsity.units.forEach((unit) => {
          total += unit['sms_alerts_count']
        })
        varsity['name'] = varsity['name'] + " (" + total + " )"
      })
    }
  },
  watch: {
    varsities() {
      this.totalSubscriber()
    },
    varsity_id() {
      this.units = this.searchSelectedVarsity()
    },
    unit_id() {
      this.unit = this.searchSelectedUnit()
    },
    units() {
      this.units.forEach((unit) => {
        unit['name'] = unit['name'] + " (" + unit.sms_alerts_count + ")"
      })
    },
  },
  mounted() {
    this.initialize()
  }
}
</script>